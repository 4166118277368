<template>
  <div class="preference-type">
    <div>
      <div v-if="!validValues?.length">
        <span>
          No values found.
        </span>
      </div>
      <div
        v-else
        class="preference-type-collection__items"
      >
        <div
          v-for="(item, index) in validValues"
          :id="'tr' + index"
          :ref="el => { if (el) vForDivs[i] = el }"
          :key="index"
          class="preference-type-collection__item"
          data-arrow-navigable-item
        >
          <div
            v-if="validBaseType === 'Integer'"
            class="preference-type-collection__item_value"
          >
            <input
              :id="'valueInput' + index"
              :value="displayAsNumber(item.value)"
              type="number"
              disabled
            >
          </div>
          <div
            v-else-if="validBaseType === 'Double'
              || validBaseType === 'Decimal'"
            class="preference-type-collection__item_value"
          >
            {{ displayAsNumber(item.value) }}
          </div>
          <div
            v-else
          >
            <template
              v-if="customEditor === 'RtfEditor'"
            >
              <RtfViewer
                :name="`${preferenceName} (${index + 1}/${validValues.length})`"
                :rtf="item.value"
              />
            </template>
            <template
              v-else
            >
              <textarea
                :id="'valueInput' + index"
                :ref="el => setIsTextLongArray(el, index)"
                :value="item.value"
                class="preference-type-collection__item_value_textarea"
                type="text"
                :rows="isTextLong[index] ? 2 : 1"
                disabled
                @focus="onTextareaFocus(index)"
              />
              <div
                v-if="isTextLong[index]"
                class="preference-type-collection__input_item_show_buttons"
              >
                <span
                  v-if="!isTextareaOpen[index]"
                  @click="onShowMoreClicked(index)"
                >
                  Show more
                </span>
                <span
                  v-else-if="isTextareaOpen[index]"
                  @click="onShowLessClicked(index)"
                >
                  Show less
                </span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed, watch, reactive, ref, nextTick,
} from 'vue';
import RtfViewer from '@/components/shared/rtf-viewer.vue';

import { useUtilsStore } from '@/store/utils';

export default {
  components: {
    RtfViewer,
  },

  props: {
    preferenceValueDTO: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    console.log('Init type collection');

    const { toHash } = useUtilsStore();

    const vForDivs = ref([]);
    const newItemAdded = ref(false);

    const validValues = computed(() => props.preferenceValueDTO.values);
    const validBaseType = computed(() => props.preferenceValueDTO.baseType);
    const customEditor = computed(() => props.preferenceValueDTO.customEditor);
    const preferenceName = computed(() => props.preferenceValueDTO.name);

    const displayAsNumber = (value) => Number(value);
    const stringFromNumber = (value) => value.toString();

    const isTextLong = reactive([]);

    const isTextareaOpen = reactive([]);
    const twoRowsHeight = 42;

    const setIsTextLongArray = (el, index) => {
      isTextLong[index] = el?.scrollHeight > twoRowsHeight;
    };

    const onTextareaFocus = (index) => {
      const inputElement = document.getElementById(`valueInput${index}`);
      const trElement = document.getElementById(`tr${index}`);
      if (inputElement?.scrollHeight > twoRowsHeight) {
        inputElement.style.height = `${inputElement?.scrollHeight}px`;
        isTextareaOpen[index] = true;
        trElement.style.verticalAlign = 'top';
      }
    };

    const onShowMoreClicked = (index) => {
      onTextareaFocus(index);
    };

    const onShowLessClicked = (index) => {
      const inputElement = document.getElementById(`valueInput${index}`);
      const trElement = document.getElementById(`tr${index}`);
      inputElement.style.height = `${twoRowsHeight / 16}rem`;
      isTextareaOpen[index] = false;
      trElement.style.verticalAlign = 'inherit';
    };

    const onTextareaBlur = (e) => {
      e.target.style.height = '1.3110625rem';
    };

    watch(() => validValues.value, async () => {
      console.log(vForDivs.value);
      if (newItemAdded.value) {
        newItemAdded.value = false;
        await nextTick();
        vForDivs.value.undefined.firstChild.focus();
      }
    },
    { deep: true });

    return {
      displayAsNumber,
      stringFromNumber,
      vForDivs,
      validValues,
      validBaseType,
      customEditor,
      preferenceName,
      toHash,
      onTextareaFocus,
      onTextareaBlur,
      setIsTextLongArray,
      onShowMoreClicked,
      onShowLessClicked,
      isTextLong,
      isTextareaOpen,
    };
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
}

.preference-type-collection__items {
  padding-bottom: 1rem;
  border-top: 0.0625rem solid var(--colour-border-light);
}

.preference-type-collection__item {
  padding: 0.75rem 0;
  border-bottom: 0.0625rem solid var(--colour-border-light);
}

.preference-type-collection__item_value > input {
  border: none;
}

.preference-type-collection__item_value_textarea {
  display: block;
  width: 100%;
  overflow: hidden;
  color: var(--colour-text-dark);
  resize: none;
  background-color: var(--colour-bg-default);
  border: 0;
}

.preference-type-collection__input_item_show_buttons {
  padding-top: 0.5rem;
  text-decoration: underline;
}

.preference-type-collection__input_item_show_buttons:hover {
  color: var(--colour-text-dark);
  cursor: pointer;
}
</style>
